const Boxes = {
  box1: function () {
      var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-7, 15, 1,-15],keepaspectratio:true,
         axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false,pan:{enabled:false}, zoom:{enabled:false}});
         brd1.options.circle.highlight=false;
         brd1.options.text.highlight=false;
         brd1.options.image.highlight=false;
         brd1.options.point.showInfobox=false;
      var resize = function () {
          brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
          brd1.fullUpdate();
          };
      window.onresize = resize;
      brd1.options.layer['image']=10;
	brd1.options.layer['point']=12;
	brd1.create('text', [5, 12.5, '<b>Ionic Bond</b>'], {fixed:true, fontSize:function(){return 32*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('text', [0, 11, 'Push Cl atom towards Na atom to form bond!'], {fixed:true, fontSize:function(){return 25*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('image', ['/assets/left.svg', [-7.3, -6],[6,6]],{fixed:true, rotate:-10});
	var k1 = brd1.create('image', ['/assets/right.svg', [22-2.85, -6],[6,6]],{fixed:false, rotate:10, highlight:true});
	brd1.on('move', function(){k1.moveTo([Math.max(k1.X(), 14.5), k1.Y()]);});
	brd1.create('text', [function(){return k1.X()+1},function(){return k1.Y()-0.25} , 'Drag me'], {fixed:true, fontSize:function(){return 25*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('image', ['/assets/Nan.svg', [-1, -1],[2,2]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [-0.3, 1.65],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [-0.3, -2.25],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [-0.3, 2.65],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [-0.3, -3.25],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [2.6, -0.3],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [-3.25, -0.3],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [2.12-0.35, 2.12-0.3],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [-2.12-0.3, 2.12-0.3],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [-2.12-0.3, -2.12-0.2],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [2.12-0.35, -2.12-0.2],[0.6,0.6]],{fixed:true});
//	brd1.create('text', [-0.4, 0.1, '<b>Na</b>'], {fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	var i=0; var j=0;
	k1.on('drag', function(){if(k1.X()<=14.5){return i=1}});
	k1.on('up', function(){if(i==1){k1.moveTo([14.5, -6], 100)}});
	var a =function(){return k1.X()-3.25};
	var c1 = brd1.create('circle', [[function(){return a()},0],[function(){return a()+4}, 0]],{fixed:true, strokeColor:'black', fillColor:'white', strokeWidth:4});
	brd1.create('circle', [[function(){return a()},0],[function(){return a()+3}, 0]],{fixed:true, strokeColor:'black', fillColor:'white', strokeWidth:4});
	brd1.create('circle', [[function(){return a()},0],[function(){return a()+2}, 0]],{fixed:true, strokeColor:'black', fillColor:'white', strokeWidth:4});
  var pt = brd1.create('point', [2.82, 2.82], {size:0, name:'', trace:false, strokeWidth:0.5, color:'black'});
//
//	brd1.create('image', ['/assets/minus.svg', [function(){return pt.X()-0.35}, 2.82-0.3],[0.6,0.6]],{fixed:true});
//

	brd1.create('circle', [[0,0],[4, 0]],{visible:true,fixed:true, strokeColor:'black', fillColor:'white', strokeWidth:4, dash:function(){if(i==0){return 0}else{return 2}}});
//
	brd1.create('circle', [[0,0],[3, 0]],{fixed:true, strokeColor:'black', fillColor:'white', strokeWidth:4});
	brd1.create('circle', [[0,0],[2, 0]],{fixed:true, strokeColor:'black', fillColor:'white', strokeWidth:4});
  var pt1 = brd1.create('point', [2.82,2.82],{visible:false});
  var pt1a = brd1.create('point', [function(){return 0.5*a();}, 4.5],{visible:false});
	var pt2a = brd1.create('point', [function(){return 0.5*a();}, -3],{visible:false});
	var pt2b = brd1.create('point', [function(){return 0.5*a();}, 3],{visible:false});
	var pt2c = brd1.create('point', [function(){return 0.5*a();}, 0],{visible:false});
	var pt3 = brd1.create('point', [function(){return a()-2.94+0.3}, 2.78+0.3],{visible:false});
  var path =brd1.create('spline', [pt1, pt1a, pt3],{dash:1, strokeWidth:1, strokeColor:'red',visible:function(){if(i==1){return true}else{return false}}});
  var glid=brd1.create('glider', [2.82, 2.82, path],{name:'', face:'>', size:1});
  brd1.create('image', ['/assets/minus.svg', [2.82-0.35, 2.82-0.3],[0.6,0.6]],{fixed:true,
    opacity:function(){if(i==0){return 1} else{return 0.35}}});
  /*brd1.create('image', ['/assets/minus.svg', [function(){return 0.25*a();}, 4.02-0.3],[0.6,0.6]],{fixed:true,
      opacity:function(){if(i==0){return 0} else{return 0.35}}});
      brd1.create('image', ['/assets/minus.svg', [function(){return 0.75*a();}, 4.02-0.3],[0.6,0.6]],{fixed:true,
        opacity:function(){if(i==0){return 0} else{return 0.35}}});*/
  k1.on('drag', function(){
      if(i==1)
      {glid.startAnimation(1, 25, 1)}
    });
  //  k1.on('up', function(){glid.stopAnimation()});
  //glid.stopAnimation();
	var cl =brd1.create('image', ['/assets/Cln.svg', [function(){return a()-1}, -1],[2,2]],{fixed:true});
	//brd1.create('spline', [pt1, pt2a, pt3],{dash:1, strokeWidth:1, strokeColor:'red',visible:function(){if(i==1){return true}else{return false}}});
	//brd1.create('spline', [pt1, pt2b, pt3],{dash:1, strokeWidth:1, strokeColor:'red',visible:function(){if(i==1){return true}else{return false}}});
	//brd1.create('spline', [pt1, pt2c, pt3],{dash:1, strokeWidth:1, strokeColor:'red',visible: function(){if(i==1){return true}else{return false}}});
//	brd1.create('spline', [[-1,-1], [function(){return 0.5*a()}, -1],[function(){return a()}, -1]],{dash:1, strokeWidth:1});
//	brd1.create('spline', [[-1,-1], [function(){return 0.5*a()-1}, 0],[function(){return a()-1}, -1]],{dash:1, strokeWidth:1});
	brd1.create('image', ['/assets/chat.svg', [15, -14],[8,6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [function(){return a()-0.3}, 1.65],[0.6,0.6]],{fixed:true});
	brd1.create('image', ['/assets/minus.svg', [function(){return a()-0.3}, -2.25],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-0.3}, 2.65],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-0.3}, -3.25],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()+2.6}, -0.3],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-3.25}, -0.3],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()+2.12-0.35}, 2.12-0.3],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-2.12-0.3}, 2.12-0.3],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-2.12-0.3}, -2.12-0.2],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()+2.12-0.35}, -2.12-0.2],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()+2.82-0.35}, 2.82-0.3],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()+2.82-0.35}, -2.82-0.2],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-2.82-0.35}, -2.82-0.2],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-0.3}, 3.65],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-0.3}, -4.25],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()+3.6}, -0.3],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-4.3}, -0.3],[0.6,0.6]],{fixed:true});
brd1.create('image', ['/assets/minus.svg', [function(){return a()-2.94}, 2.78],[0.6,0.6]],{fixed:true, visible:function(){if(i==1){return true} else{return false}}});
//brd1.create('text', [function(){return a()-0.4}, 0.1, '<b>17 p +17 n</b>'], {fixed:true, fontSize:function(){return 12*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
k1.on('up', function(){ if(i==1){pt.moveTo([a()-2.82-0.1, 2.79])}});
//
//brd1.create('image', ['/assets/exchange.svg', [function(){return 0.5*(k1.X()-5.5)}, -1.5],[2.5,2]],{fixed:true, visible:function(){if(i==1){return true}else{return false}}});

//brd1.create('image', ['/assets/step.svg', [function(){return 0.5*(k1.X()-9.5)}, 2.],[6,4]],{fixed:true, rotate:5, visible:function(){if(i==1){return true}else{return false}}});

brd1.create('text', [-1.5, 6, 'Na: 2, 8, 1'], {fixed:true, fontSize:function(){return 25*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', visible:function(){if(i==0){return true}else{return false}}});
//
brd1.create('text', [-1.5, -5, function(){return 'Net Charge = ' + i}], {fixed:true, fontSize:function(){return 22*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
brd1.create('text', [-1.5, 6, 'Na^+: 2, 8'], {fixed:true, fontSize:function(){return 25*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', visible:function(){if(i==1){return true}else{return false}}});
//
brd1.create('text', [function(){return a()-1.5}, 6, 'Cl^-: 2, 8, 8'], {fixed:true, fontSize:function(){return 25*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',visible: function(){if(i==1){return true}else{return false}}});
//
brd1.create('text', [function(){return a()-1.5}, 6, 'Cl: 2, 8, 7'], {fixed:true, fontSize:function(){return 25*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',visible: function(){if(i==0){return true}else{return false}}});
//
brd1.create('text', [function(){return a()-1.5}, -5, function(){return 'Net Charge = ' + -1*i}], {fixed:true, fontSize:function(){return 22*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
brd1.create('text', [17, -10.5, 'Not Bonded!'], {fixed:true, fontSize:function(){return 25*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',visible: function(){if(i==0){return true}else{return false}}});
brd1.create('text', [17.5, -9.5, ' Bonded!'], {fixed:true, fontSize:function(){return 25*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',visible: function(){if(i==1){return true}else{return false}}});
  },
}
export default Boxes;
