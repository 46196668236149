<template>
  <div>
    <h3 ref="def">
      Bonds
    </h3>
    <p>Everything thing that you see, touch, and feel around you is made of atoms &mdash; the indivisible and indestructible building blocks of nature. Have you ever wondered what keeps these atoms together in the matter? The atoms in the matter are held together by an invisible force called a chemical bond. You can think of a chemical bond as an invisible, sticky 'glue' that keeps the atoms together.</p>
    <h3 ref="rvsp">
      Types of Chemical Bonds
    </h3>
    <p>There are two most basic types of chemical bonds &mdash; ionic bonds and covalent bonds. The nature of a chemical bond really depends on the chemical properties of the atoms being bonded.</p>
    <ul style="list-style-type: square;">
      <li>
        <h5>Ionic Bonds</h5>
        These bonds are formed through a complete transfer of electrons from one atom to another atom. For such transfer to occur, the atoms must have very different electronegativities. For example - the chemical bonds keeping Sodium (or Na) and Chlorine (or Cl) atoms together in common salt (NaCl) are ionic bonds. $$Na + Cl \rightarrow NaCl $$ Such types of bonds are often formed between a metal and non-metal.
      </li>
      <li>
        <h5>Covalent Bonds</h5>
        These bonds are formed by the sharing of electrons between two or more atoms. Such types of bonds are often formed between atoms having similar electronegativities.
      </li>
      For example &mdash; the chemical bonds keeping the Carbon (C) and Hydrogen (H) atoms together in \(CH_4\) are covalent in nature. $$C + 2H_2 \rightarrow CH_4$$
    </ul>
    <h3 ref="pg">
      MagicGraph: Formation of an Ionic Bond
    </h3>
    <p>Through this magicGraph, you will learn how an ionic bond is formed.</p>
    <h5>To Get Started</h5>
    <p>
      You're given isolated Sodium (Na) and Chlorine (Cl) atoms.
      Drag the Cl atom towards the Na atom. Once the two atoms reach a close enough proximity, a complete transfer of the valence shell electron from Na atom to Cl atom takes place. This leads to the formation of an ionic bond between Na and Cl, and the creation of NaCl molecule &mdash; also known as common salt.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'IonicBonds',
  components: {
  },
  data () {
    return {
      style: {layout: "margin: 10px"},
    }
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Ionic Bond';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Chemical Bond?',img:'/assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Types of Chemical Bonds', img:'/assets/number-2.svg', action: () => this.goto('pg')},
      {title: 'Formation of an Ionic Bond', img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newChem =true;
    this.$store.commit('navigation/replaceChem', newChem);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
